import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { Box, Grid, Paper, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  listCoupon: {
    width: (props) => props - 32,
    overflow: 'auto',
    whiteSpace: 'nowrap',
  },
  root: {
    width: 295,
    height: 115,
    marginRight: 16,
    display: 'inline-block',
  },
  count: {
    backgroundColor: '#253745',
    width: 40,
    textAlign: 'center',
    position: 'absolute',
    right: 0,
    top: '12px',
  },
}));

export default (props) => {
  const { card, promotion, date } = props;

  const screenWidth = typeof window !== 'undefined' ? window.screen.width : 0;
  const classes = useStyles(screenWidth);
  const dispatch = useDispatch();

  return (
    <>
      <Grid item xs={12}>
        <Typography variant='h6' component='p' color='textPrimary'>โค้ดส่วนลดพิเศษ</Typography>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.listCoupon}>
          {
            (card?.benefits.coupons || []).map((coupon, index) => {
              return (
                <div className={classes.root} key={index}>
                  <Paper style={{ backgroundColor: coupon.status == 'available' ? '#ECF3F7' : '#DCDCDC', position: 'relative' }}>
                    <Box pl={2} pt={1}>
                      <Typography variant='h6' component='h5' style={{ color: coupon.status == 'available' ? '#253745' : '#b0b0b0' }}>ส่วนลดค่ายา {parseInt(coupon.promotion.discount_price)} บาท</Typography>
                      <Typography variant='caption' style={{ color: coupon.status == 'available' ? '#253745' : '#b0b0b0' }} component='p'>ขั้นต่ำ 20 บาท</Typography>
                    </Box>
                    <Box pl={2} pt={1}>
                      <Paper className={classes.count}>
                        <Typography variant='inherit' component='span' style={{ color: 'white' }}>{index + 1}/{promotion}</Typography>
                      </Paper>
                    </Box>
                    <Box pl={2} pt={1.5} pb={0.5}>
                      <Typography variant='body2' style={{ color: coupon.status == 'available' ? '#253745' : '#b0b0b0' }} component='p'>ใช้งานได้ {coupon.promotion.start_date}</Typography>
                      <Typography variant='body2' style={{ color: coupon.status == 'available' ? '#253745' : '#b0b0b0' }} component='span'>หมดอายุ {date}</Typography>
                      {
                        coupon.status == 'used' ?
                          <Typography variant='body2' style={{ color: coupon.status == 'used' ? '#b0b0b0' : '#253745', float: 'right', marginRight: '13px' }} component='span'>ใช้งานแล้ว</Typography>
                          : <></>
                      }
                    </Box>
                  </Paper>
                </div>
              )
            })
          }
        </div>
      </Grid>
    </>
  )
}