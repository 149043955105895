import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Card, CardContent, CardMedia} from '@material-ui/core';
import Divider from '@material-ui/core/Divider';

function colorToLinearGradient(colorCode) {
  const r = parseInt(colorCode.slice(1, 3), 16);
  const g = parseInt(colorCode.slice(3, 5), 16);
  const b = parseInt(colorCode.slice(5, 7), 16);

  const color1 = `rgba(${r},${g},${b},1)`;
  const color2 = `rgba(${r + 30},${g + 30},${b + 30},1)`;

  const gradient = `linear-gradient(45deg, ${color1} 60%, ${color2} 100%)`;
  return gradient;
}

const useStyles = makeStyles(theme => ({
  cardBg: {
    borderRadius: 16,
    height: 155, width: 311,
    padding: '0 12px',
    position: 'relative'
  },
  badge: {
    height: 64,
    marginRight: 8,
  },
  cardMedia: {
    position: 'absolute',
    top: 0, left: 0,
    height: '100%', width: '100%',
    objectFit: 'cover',
    zIndex: 0
  },
  cardContent:{
    position: 'relative',
    padding: '0 12px',
  },
  cardOverlay:{
    position: 'absolute',
    top: 0, left: 0,
    height: '100%', width: '100%',
    opacity: 0.65,
  },
  imgBadge: {
    width: '40%',
    marginLeft: '15px',
    marginRight: '10px',
  },
}));

function getColor(val = '') {
  const res = val.toLowerCase().replaceAll(' ', '');
  if (res.includes('gold')) return '#C8A219';
  if (res.includes('standard')) return '#72787C';
  if (res.includes('platinum')) return '#414B54';
  return '#72787C';
}
function getBadgeAsset(badgeUrl){
  if(badgeUrl){
    return badgeUrl.large
  }
  return '../../../assets/images/badge_goldpackage.svg';
}

function getcardFrontImgAsset(cardFrontImgUrl){
  if(cardFrontImgUrl){
    return cardFrontImgUrl.large
  }
  return 'https://staging-service.moronline.me/media/__sized__/__placeholder__/front_image-thumbnail-720x405.png';
}

export default function MemberShipCard(props) {
  const classes = useStyles();
  const { cardFrontImg, cardBadge, expiredDate, plan, name } = props;

  if (!name) return false;

  return (
    <div style={{ background: colorToLinearGradient(getColor(plan)), borderRadius: '20px', margin: '0 15px', padding: '0px 5px 20px 5px' }}>
      {/* <Box top='0' left='0' position='absolute' display='flex' height='100%' width='100%' alignItems='center' zIndex='0'>
        <div className={classes.cardOverlay} style={{ background: colorToLinearGradient(getColor(plan))}}></div>
      </Box> */}
      <Box display='flex' height='90px' alignItems='center'>
        <div className={classes.imgBadge}>
          <img className={classes.badge} src={`${getBadgeAsset(cardBadge)}`} />
        </div>
        <div>
          <Typography variant='subtitle1' component='p' style={{color:'white'}}>ระดับสมาชิก:</Typography>
          <Typography variant='h5' component='p' style={{color:'white'}}>{plan}</Typography>
        </div>
      </Box>
      <Divider />
      <Box display='flex' flexDirection='column' height='68px' justifyContent='center'>
        <Typography variant='subtitle1' component='p' style={{color:'white'}}>ชื่อสมาชิก: {name}</Typography> {/* อาจจะเอาจาก /me */}
        <Typography variant='subtitle1' component='p' style={{color:'white'}}>วันหมดอายุ: {expiredDate}</Typography>
      </Box>
    </div>
  )
}
