import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { navigate } from 'gatsby';
import { navigateTo } from '../../components/CrossPlatformLink';
import { Avatar, Card, CardActionArea, CardContent, Grid, Typography } from '@material-ui/core';

const menus = [
  {
    'name': 'ปรึกษาแพทย์',
    'icon': 'appointment',
    'condition': 'ไม่จำกัดครั้ง',
    'path': () => navigate('/membership/consult/chooseCategory'),
  },
  {
    'name': 'ปรึกษาเภสัช/RX',
    'icon': 'drug',
    'condition': 'ส่วนลดค่ายา',
    'path': (source) => navigateTo(source, 'me.moronline://drugStore'),
  },
]

const useStyles = makeStyles(theme => ({
  button: {
    width: 164,
    backgroundColor: '#FFFFFF',
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginLeft: '12px',
    marginTop: '12px',
  },
}));

export default (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { source } = useSelector(state => state.session);

  return (
    <>
      <Grid item xs={12}>
        <Typography variant='h6' component='p' color='textPrimary'>เมนู</Typography>
      </Grid>
      {
        menus.map((menu, index) => {
          return (
            <Grid item xs={6} key={index}>
              <Card className={classes.button} onClick={() => menu.path(source)}>
                <CardActionArea>
                  <Avatar alt={menu.icon} src={require(`../../assets/images/${menu.icon}.svg`)} className={classes.large} />
                  <CardContent>
                    <Typography gutterBottom variant='h6' component='h5' color='textPrimary'>
                      {menu.name}
                    </Typography>
                    <Typography variant='caption' color='textPrimary' component='p'>
                      {menu.condition}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          )
        })
      }
    </>
  )
}