import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { navigate } from 'gatsby';
import { useSelector, useDispatch } from 'react-redux';
import { navigateTo } from '../../components/CrossPlatformLink';
import { getDate } from '../../helpers/date';
import { getMembershipCardList, setCurrentMemberCard, setForceMember } from '../../actions/membership';
import { createLoadingSelector } from '../../reducers/api';
import { createMembershipCardId, createMembershipCardSelector, createMembershipCards } from '../../reducers/memberships';
import Layout from '../../components/v2/Layout';
import MembershipCard from '../../components/v2/MembershipCard';
import SlideDialog from '../../components/v2/SlideDialog';
import LoaderView from '../../components/LoaderView';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import CardActionArea from '@material-ui/core/CardActionArea';
import Paper from '@material-ui/core/Paper';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Button, Divider } from '@material-ui/core';

import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import MembershipDetail from '../../components/MembershipDetail';
import MemberPromotion from '../../components/MembershipDetail/memberPromotion';

const useStyles = makeStyles((theme) => ({
  newCard: {
    borderRadius: '20px',
    margin: '0 15px',
    padding: '0px 5px 20px 5px',
    // borderColor: '#00BEFF',
    borderColor: 'rgb(0, 190, 255, 0.3)',
    border: 'solid',
  },
  imgClick: {
    width: '25%',
    opacity: '0.5',
  },
  carousel: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    margin: '20px 0',
  },
  addCardText: {
    color: 'rgb(0, 190, 255, 0.3)',
  },
}));

const emptyCard = {
  'id': 'new',
  'type': {'badge': null, 'name': 'Add new card'},
  'benefits': {'coupons': null},
  'created': null,
  'reference': null,
  'issue_date': null,
  'expired_date': null,
}

export default function Membership(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [dialog, setDialog] = useState(true) // check ว่าเคยเห็น slidedialog ยัง // Api.getWelcome() ? false : true
  const currentCard = useSelector(createMembershipCardSelector);
  const cards = useSelector(createMembershipCards);
  const isLoading = useSelector(createLoadingSelector(['membership/me/cards/get']));
  const { source, user } = useSelector(state => state.session);
  const [isMember, setIsMember] = useState(false);
  const [card, setCard] = useState();

  useEffect(() => {
    const checkUserMemberStatus = async () => {
      if (user) {
        dispatch(getMembershipCardList());

        if(!user?.is_member){
          setIsMember(false)
          await navigate('/membership/pricing')
        }
        else{
          setIsMember(true)
        }
      }
    }
    checkUserMemberStatus();
  }, [user]);

  useEffect(() => {
    setCard(cards[0]);
  }, [isLoading]);

  const handleChangeCard = (index) => {
    if (cards[index]) {
      setCard(cards[index]);
      dispatch(setCurrentMemberCard(cards[index]));
    } else {
      setCard(emptyCard);
      dispatch(setCurrentMemberCard(cards[0])); // case lastest card
    }
  }

  const handleClickNewCard = async (e) => {
    if (card?.id === 'new') {
      dispatch(setForceMember(true));
      await navigate('/membership/pricing');
    }
  }

  const date = getDate(card?.expired_date);
  const promotion = card?.benefits.coupons?.length;

  const onClickPopup = () => {
    setDialog(false);
    navigate('/membership/consult/chooseCategory')
  }

  return (
    <Layout bg='bg2' title='สมาชิก'>
      <LoaderView loading={isMember? isLoading : true} >
        <SlideDialog
          open={dialog}
          secondaryButtonText='ไว้ภายหลัง'
          primaryButtonText='จองนัดหมาย'
          onClickPrimary={onClickPopup}
          onClickSecondary={() => setDialog(false)}
        >
          <Box textAlign='center' py={2}>
            <img src={require('../../assets/images/noto_confetti-ball.svg')} />
            <Typography variant='h6' component='h1' color='textPrimary'>
              ยินดีต้อนรับสู่สมาชิก {card?.type.name}
            </Typography>
            <Typography variant='subtitle2' component='p' color='textPrimary'>
              คุณสามารถเริ่มทำการนัดหมายเพื่อตรวจสุขภาพ เพื่อทำความรู้จักกับร่างกายของคุณให้มากยิ่งขึ้น!
            </Typography>
          </Box>
        </SlideDialog>

        <Grid container spacing={3}>
          <div className={classes.carousel}>
            <Carousel
              showThumbs={false}
              showStatus={false}
              showArrows={false}
              onChange={handleChangeCard}
              onClickItem={handleClickNewCard}
            >
              {
                cards.map((value, index) => {
                  var date = getDate(value?.expired_date);
                  return (
                    <MembershipCard
                      cardFrontImg={value?.type.front_image}
                      cardBadge={value?.type.badge}
                      plan={value?.type.name}
                      expiredDate={date}
                      name={user?.fullname}
                    />
                  )
                })
              }
              <div className={classes.newCard}>
                <Box
                  display='flex'
                  height='110px'
                  alignItems='center'
                  justifyContent='center'
                  flexDirection='column'
                  style={{ margin: '30px 0 15px 0'}}
                >
                  <div className={classes.imgClick}>
                    <img src={require(`../../assets/images/addNewCard.svg`)} />
                  </div>
                  <div>
                    <Typography variant='subtitle1' component='p' className={classes.addCardText}>add card</Typography>
                  </div>
                </Box>
              </div>
            </Carousel>
          </div>
          {/* {
            card?.id === emptyCard?.id && (
              <Grid item xs={12}>
                <Typography variant='h6' component='p' color='textPrimary'>เพิ่มบัตรสมาชิก</Typography>
              </Grid>
            )
          } */}
          {
            card?.id !== emptyCard?.id && (
              <MembershipDetail />
            )
          }
          {
            card?.id !== emptyCard?.id && (
              <MemberPromotion card={card} promotion={promotion} date={date} />
            )
          }
        </Grid>
      </LoaderView>
    </Layout>
  )
}

